import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Form, Input, List, Radio, Row, Select, Space, Upload, message } from 'antd';
import { API_METHODS } from '../../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../hooks/useApi';
import { UploadOutlined } from "@ant-design/icons";
import { isEmpty } from '../../../generalFunctions';

const AddSiteVisitForm = (props) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    // const { site } = getClientConfig();
    const [formId, setFormId] = useState();
    const [currentQuestions, setCurrentQuestions] = useState();
    const [dummyState, setDummyState] = useState();
    const { visitFormId } = useParams();
    const [siteList, setSiteList] = useState();
    const [sites, getSites] = useApi(
        `/mis/visit-form/sites`,
        null,
        API_METHODS.GET
    );
    //check
    const [responseData, getRequest] = useApi(
        `/admin/visit-form/${visitFormId}`,
        null,
        API_METHODS.GET
    );

    const [startVisitFormFillingResponseData, startVisitFormFillingRequest] = useApi(
        `/mis/visit-form/start-form`,
        null,
        API_METHODS.POST,
        true
    );

    const [addVisitFormResponseData, addVisitFormRequest] = useApi(
        `/mis/visit-form/submit-form`,
        null,
        API_METHODS.POST,
        true
    );
    const [raList, setRAList] = useState();
    const [ra, getRA] = useApi(`/mis/common/ra`, null, API_METHODS.GET);

    const raSelected = (ra_id) => {
        getSites(null, API_METHODS.GET, `/mis/visit-form/sites?raId=${ra_id}`);
    }

    const raCleared = (ra_id) => {
        getSites();
    };

    const startVisitFormFilling = () => {
        form.validateFields().then((values, fileList) => {
            let formData = new FormData();
            for (const name in values) {
                if (name === "selfi") {
                    if (values["selfi"] !== undefined) {
                        formData.append(
                            "selfi",
                            values["selfi"].file.originFileObj,
                            values["selfi"].file.name
                        );
                    }
                }
            }
            formData.append("dbTableSiteId", values["dbTableSiteId"]);
            formData.append("formTime", values["formTime"]);
            startVisitFormFillingRequest(formData);
        });
    }

    const addSiteVisitForm = () => {
        form.validateFields().then((values, fileList) => {
            let newAnswers = [];
            let newImages = [];
            let questionIds = Object.keys(values);
            for (let i = 0; i < questionIds.length; i++) {
                if (!questionIds[i].toString().includes("images") && questionIds[i] !== "siteImages" && questionIds[i] !== "formTime" && questionIds[i] !== "selfi" && questionIds[i] !== "dbTableSiteId" && questionIds[i] !== "theftComment") {
                    const questionId = questionIds[i];

                    //remove images if selected answer has isImageSelectable=false 
                    const currentQuestion = currentQuestions.find(a => a.questionId == questionId);
                    const isImageSelectable = currentQuestion?.options?.find(o => o.id === values[questionId])?.isImageSelectable;

                    //remove subquestions if selected answer has good=false 
                    if (currentQuestion?.subquestions?.length > 0) {
                        const isIncludeSubquestion = currentQuestion.options?.find(o => o.id === values[questionId])?.id != 1;
                        if (!isIncludeSubquestion) {
                            const subquestionIds = currentQuestion.subquestions.map(s => s.questionId);
                            subquestionIds.forEach(sqId => {
                                questionIds = questionIds.filter(qId => qId !== sqId);
                            });

                        }
                    }

                    const answerData = {
                        "images": isImageSelectable ? currentQuestions.find(a => a.questionId == questionId)?.images : [],
                        "answerId": values[questionId],
                        "questionId": questionId
                    }

                    //for Theft Incident = Other
                    if (questionId === "29" && values.theftComment && values[questionId] === 6) {
                        answerData.remarks = values.theftComment;
                    }

                    newAnswers.push(answerData);
                    if (values["images" + questionId]) {
                        const newImagesArray = (values["images" + questionId].fileList.map(f => { return { questionId: questionId, originFileObj: f.originFileObj, name: f.name, } }));
                        newImages = [...newImages, ...newImagesArray];
                    }
                }
            };

            const formData = new FormData();
            for (let i = 0; i < newImages.length; i++) {
                formData.append(
                    newImages[i].questionId,
                    newImages[i].originFileObj,
                    newImages[i].name,
                );
            }

            let siteImages = values.siteImages?.fileList.map(f => { return { originFileObj: f.originFileObj, name: f.name, } })
            for (let i = 0; i < siteImages?.length; i++) {
                formData.append(
                    "siteImages",
                    siteImages[i].originFileObj,
                    siteImages[i].name,
                );
            }
            formData.append("answers", JSON.stringify(newAnswers));
            formData.append("dbTableSiteId", JSON.stringify(values.dbTableSiteId));
            formData.append("id", JSON.stringify(formId));
            formData.append("formTime", JSON.stringify(values.formTime));
            addVisitFormRequest(formData);
        }).catch(e => console.log(e));
    }

    const uploadProps = {
        beforeUpload: (file) => {
            const isImage = file.type === "image/png" || file.type === "image/jpeg";
            if (!isImage) {
                message.error(`${file.name} error. Only PNG and JPEG files supported`);
            }
            return isImage || Upload.LIST_IGNORE;
        },
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const handleAnswerChange = (e) => {
        setDummyState(e);
    }

    useEffect(() => {
        if (startVisitFormFillingResponseData.data && !startVisitFormFillingResponseData.isLoading && isEmpty(startVisitFormFillingResponseData.error)) {
            if (startVisitFormFillingResponseData.data) {
                const { id, questions } = startVisitFormFillingResponseData.data;
                setFormId(id);
                setCurrentQuestions(questions);
            }
        } else if (startVisitFormFillingResponseData.error && !startVisitFormFillingResponseData.isLoading) {
            message.info(startVisitFormFillingResponseData.error);
        }
    }, [startVisitFormFillingResponseData]);

    useEffect(() => {
        getSites();
        getRA();
    }, []);

    useEffect(() => {
        if (sites.data && !sites.isLoading && isEmpty(sites.error)) {
            setSiteList(sites.data);
        } else if (sites.error && !sites.isLoading) {
            message.info(sites.error);
        }
    }, [sites]);


    useEffect(() => {
        if (ra.data && !ra.isLoading && isEmpty(ra.error)) {
            setRAList(ra.data);
        } else if (ra.error && !ra.isLoading) {
            message.info(ra.error);
        }
    }, [ra]);

    useEffect(() => {
        if (addVisitFormResponseData.data && !addVisitFormResponseData.isLoading) {
            message.info("Site visit details saved successfully.");
            navigate(-1);
        } else if (addVisitFormResponseData.error && !addVisitFormResponseData.isLoading) {
            message.info(addVisitFormResponseData.error);
        }
    }, [addVisitFormResponseData]);

    return (
        <Card loading={responseData.isLoading}>
            <Form form={form} layout='vertical'>
                <Card title={"Start Details"} style={{ marginBottom: 15 }}>
                    <Row gutter={48}>
                        <Col span={5}>
                            <Form.Item
                                label={<strong>Roaming Attendant</strong>}
                            >
                                <Select
                                    placeholder="Select Roaming Attendant"
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                        0
                                    }
                                    onSelect={raSelected}
                                    onClear={raCleared}
                                >
                                    {raList?.map((option) => (
                                        <Select.Option key={option.id} value={option.id}>
                                            {option.displayName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                name="dbTableSiteId"
                                label={<strong>Sites</strong>}
                            >
                                <Select
                                    placeholder="Select Site"
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                        0
                                    }
                                    disabled={currentQuestions}
                                >
                                    {siteList?.map((option) => (
                                        <Select.Option key={option.id} value={option.id}>
                                            {option.site_id}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name={"formTime"} label="Start Time">
                                <DatePicker
                                    allowClear
                                    format={"DD-MM-YYYY HH:mm:ss"}
                                    showTime
                                    disabled={currentQuestions}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Upload Selfie" name={"selfi"} style={{ marginLeft: 25 }}>
                                <Upload
                                    {...uploadProps}
                                    customRequest={dummyRequest}
                                    listType="picture-card"
                                    // fileList={fileList}
                                    // onChange={handleChange}
                                    maxCount={1}
                                    multiple={false}
                                    disabled={currentQuestions}
                                >
                                    {
                                        <UploadOutlined />
                                    }
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                {currentQuestions && <>
                    <List
                        grid={{
                            gutter: 16,
                            column: 1
                        }}
                        dataSource={currentQuestions}
                        renderItem={(item, index) => (
                            <List.Item>
                                <Card title={(index + 1) + ". " + item.questionHeading}>
                                    <Form.Item label="" name={item.questionId}>
                                        <Radio.Group key={item.questionId} onChange={handleAnswerChange}>
                                            <Space>
                                                {item.options.map(option =>
                                                    <Radio value={option.id}>{option.tittle}</Radio>
                                                )}
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                    {(item.type == 3 && form.getFieldValue(29) === 6) && <Form.Item label="" name="theftComment" style={{ marginTop: 10 }}>
                                        <Input.TextArea rows={3} placeholder='Remarks' required />
                                    </Form.Item>}
                                    <Row>
                                        <Space>
                                            {item.options.find(option => option.id === form.getFieldValue(item.questionId))?.isImageSelectable &&
                                                <Form.Item name={"images" + item.questionId} label="" style={{ marginLeft: 25 }}>
                                                    <Upload
                                                        {...uploadProps}
                                                        customRequest={dummyRequest}
                                                        listType="picture-card"
                                                        // fileList={fileList}
                                                        // onChange={handleChange}
                                                        multiple={true}
                                                    >
                                                        {
                                                            <UploadOutlined />
                                                        }
                                                    </Upload>
                                                </Form.Item>
                                            }
                                        </Space>
                                    </Row>
                                    <Row>
                                        {item.subquestions && item.options.find(option => option.id === form.getFieldValue(item.questionId))?.id == 1 && item.subquestions.map((subQuestion, index) =>
                                            <Col span={24} style={{ marginTop: 10 }}>
                                                <Card title={(index + 1) + ". " + subQuestion.questionHeading}>
                                                    <Form.Item label="" name={subQuestion.questionId} rules={[{
                                                        required: true
                                                    }]}>
                                                        <Radio.Group key={subQuestion.questionId} onChange={handleAnswerChange}>
                                                            <Space>
                                                                {subQuestion.options.map(option =>
                                                                    <Radio value={option.id}>{option.tittle}</Radio>
                                                                )}
                                                            </Space>
                                                        </Radio.Group>
                                                    </Form.Item>

                                                    <Row>
                                                        <Space>
                                                            {subQuestion.options.find(option => option.id === form.getFieldValue(subQuestion.questionId))?.isImageSelectable &&
                                                                <Form.Item name={"images" + subQuestion.questionId} label="" style={{ marginLeft: 25 }}>
                                                                    <Upload
                                                                        {...uploadProps}
                                                                        customRequest={dummyRequest}
                                                                        listType="picture-card"
                                                                        // fileList={fileList}
                                                                        // onChange={handleChange}
                                                                        multiple={true}
                                                                    >
                                                                        {
                                                                            <UploadOutlined />
                                                                        }
                                                                    </Upload>
                                                                </Form.Item>
                                                            }
                                                        </Space>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        )}
                                    </Row>
                                </Card>
                            </List.Item>

                        )}
                    />
                    <Card title={"Site Images"}>
                        <Col span={22}>
                            <Space>
                                <Form.Item name={"siteImages"} label="" style={{ marginLeft: 25 }}>
                                    <Upload
                                        {...uploadProps}
                                        customRequest={dummyRequest}
                                        listType="picture-card"
                                        // fileList={fileList}
                                        // onChange={handleChange}
                                        multiple={true}
                                    >
                                        {
                                            <UploadOutlined />
                                        }
                                    </Upload>
                                </Form.Item>
                            </Space>
                        </Col>

                    </Card>
                </>}
            </Form>
            <Space style={{ float: "right" }}>
                <Button
                    type="default"
                    onClick={() => navigate(-1)}
                // disabled={addResponseData.isLoading}
                >
                    Cancel
                </Button>
                {currentQuestions ? <Button
                    type="primary"
                    onClick={addSiteVisitForm}
                    loading={startVisitFormFillingResponseData.isLoading}
                >
                    Save
                </Button>
                    : <Button
                        type="primary"
                        onClick={startVisitFormFilling}
                    // loading={addResponseData.isLoading}
                    >
                        Start
                    </Button>}
            </Space>
        </Card >
    );
}

export default AddSiteVisitForm;